import { configureStore } from '@reduxjs/toolkit'
import campaignSlice from './modules/Campaign/slice'

export default configureStore({
  reducer: {
    campaign: campaignSlice
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: false
  })
});
