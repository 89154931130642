import axios from 'axios';
import * as c from '../constants/config';
import { Buffer } from 'buffer';

const CallApi = (endpoint, method = 'GET', data, contentType = '') => {
  const encodedBase64Token = Buffer.from(`${c.USERNAME}:${c.PASSWORD}`).toString('base64')
  // const encodedBase64Token = window.btoa(`${c.USERNAME}:${c.PASSWORD}`)
  const authorization = `Basic ${encodedBase64Token}`;
  return axios({
    method: method,
    url: `${c.API_URL}/${endpoint}`,
    data: data,
    params: method === 'GET' ? data : {},
    headers: {
      Authorization: authorization,
      'content-type': contentType || 'application/json'
    }
  }).catch((error) => {
    console.log(error)
  });
};
export default CallApi;
