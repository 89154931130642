/* eslint-disable camelcase */
import { React } from 'react'
import Filter from './Filter'
import ListCampaign from './ListCampaign'
import '../styles/style.css'

const Campaign = (props) => {
  return (
    <div >
      <Filter />
      <ListCampaign />
    </div>
  )
};

export default Campaign;
