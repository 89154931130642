/* eslint-disable camelcase */
import { React, useEffect, useState } from 'react'
import { Button, Col, Form, Input, Row, Select, Space } from 'antd'
import { COUNTRY, PLATFORM } from '../../../constants/common'
import { useDispatch, useSelector } from 'react-redux'
import * as campaignThunk from '../thunk'
import { setCampaignQueryData } from '../slice'
import lang from '../../../language/language'
import axios from 'axios'

const { Option } = Select

const Filter = (props) => {
    const [form] = Form.useForm()
    const campaignQueryData = useSelector(state => state.campaign.campaignQueryData)
    const dispatch = useDispatch()
    const [dataCatelogy, setDataCatelogy] = useState([]);
    useEffect(() => {
        fetchDataList(campaignQueryData)
    }, [campaignQueryData])

    useEffect(() => {
        axios({
            method: 'GET',
            url: 'https://affiliate.giantmobi.com/api/list_tag.php',
            data: {},
            params: {},
            headers: {
                'content-type': 'application/json'
            }
        }).catch((error) => {
            console.log(error)
        }).then(res => {
            if (res.data) {
                setDataCatelogy(res.data)
            }
        });
    }, [])

    const handleEnter = (event) => {
        // Enter
        if (event.keyCode === 13) {
            form.submit();
        }
    }

    useEffect(() => {
        window.addEventListener('keyup', handleEnter);
        return () => {
            window.removeEventListener('keyup', handleEnter);
        };
    }, []);

    const fetchDataList = (queryData) => {
        dispatch(campaignThunk.campaignlist(queryData))
    }

    const resetInput = () => {
        form.resetFields()
        dispatch(setCampaignQueryData({
            ...campaignQueryData,
            'filters[name]': '',
            'filters[id]': '',
            'filters[geo_country]': [],
            'filters[offer_platform]': '',
            'filters[category]': '',
            offset: 1,
            limit: 20
        }))
    }

    const isNumber = (s) => {
        return !isNaN(parseFloat(s)) && isFinite(s);
    }

    const onSubmit = (data) => {
        const filterName = data.search || ''
        dispatch(setCampaignQueryData({
            ...campaignQueryData,
            'filters[name]': isNumber(filterName) === false ? filterName : '',
            'filters[id]': isNumber(filterName) === true ? filterName : '',
            'filters[geo_country]': `${data.country && data.country !== null ? data.country.join(', ') : ''}`,
            'filters[offer_platform]': data.platform || '',
            'filters[category]': data.category || '',
            offset: 1,
            limit: 20
        }))
    }

    const onFocusSelect = (value) => {
        switch (value) {
            case 'platform':
                {
                    form.setFieldValue('platform', [])
                    break
                }
            case 'country':
                {
                    form.setFieldValue('country', [])
                    break
                }

            default: console.log(value)
        }
    }

    return (
        <Form
            onFinish={onSubmit}
            layout='vertical'
            form={form}
        >
            <Row >
                <Col md={5} lg={5} style={{ paddingRight: 15 }}>
                    <Form.Item
                        name='search'
                        label={lang.search}
                        allowClear
                    >
                        <Input placeholder={lang.offersName}></Input>
                    </Form.Item>
                </Col>
                <Col md={5} lg={5} style={{ paddingRight: 15 }}>
                    <Form.Item
                        name='category'
                        label={lang.category}
                    >
                        <Select
                            showSearch
                            filterOption={(input, option) => {
                                return option.label.toLowerCase().indexOf(input.toLowerCase()) !== -1
                            }}
                            placeholder={lang.select}
                            allowClear
                        >
                            {dataCatelogy.map((item) => {
                                return <Option
                                    key={item}
                                    value={item}
                                    label={item}
                                    data={item}
                                >
                                    <p>{item}</p>
                                </Option>
                            })}
                        </Select>
                    </Form.Item>
                </Col>
                <Col md={5} lg={5} style={{ paddingRight: 15 }}>
                    <Form.Item
                        name='platform'
                        label={lang.platform}
                    >
                        <Select
                            showSearch
                            maxTagCount="responsive"
                            filterOption={(input, option) => {
                                return option.label.toLowerCase().indexOf(input.toLowerCase()) !== -1
                            }}
                            options={PLATFORM}
                            placeholder={lang.select}
                            allowClear
                            onFocus={() => onFocusSelect('platform')}
                        />
                    </Form.Item>
                </Col>
                <Col md={5} lg={5} style={{ paddingRight: 15 }}>
                    <Form.Item
                        name='country'
                        label={lang.country}
                    >
                        <Select
                            showSearch
                            mode='multiple'
                            filterOption={(input, option) => {
                                return option.label.toLowerCase().indexOf(input.toLowerCase()) !== -1
                            }}
                            placeholder={lang.select}
                            options={COUNTRY}
                            allowClear
                            onFocus={() => onFocusSelect('country')}
                            maxTagCount="responsive"
                        />
                        {/* {COUNTRY.map((item) => {
                                return <Option
                                    key={item.value}
                                    value={item.value}
                                    label={item.lable}
                                    data={item}
                                >
                                    <p>{item.lable}</p>
                                </Option>
                            })}
                        </Select> */}

                    </Form.Item>
                </Col>
                <Col md={4} lg={4} style={{ textAlign: 'right' }}>
                    <Form.Item
                        name='button'
                        label=' '
                    >
                        <Space>
                            <Button type="primary" onClick={() => form.submit()}>{lang.apply}</Button>
                            <Button type='ghost' onClick={resetInput}>{lang.clear}</Button>
                        </Space>
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    )
};

export default Filter;
