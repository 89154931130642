import { createAsyncThunk } from '@reduxjs/toolkit';
import * as CampaignService from './service';

export const campaignlist = createAsyncThunk(
  'campaign/campaignList',
  async (data, thunkAPI) => {
    const response = await CampaignService.getCampaignData(data);
    return response.data.data;
  }
)
