/* eslint-disable react/display-name */
import React from 'react';
import lang from './language/language';
// loginPhone
import CampaignPage from './modules/Campaign/pages/CampaignPage';
import * as campaignRoute from './modules/Campaign/constants/routes'

const Routes = [
  {
    path: campaignRoute.ROUTE_CAMPAIGN,
    exact: true,
    main: ({ match, props }) => <CampaignPage match={match} props={props} />,
    name: lang.login_phone,
    permission: 'ALL'
  }
];

export default Routes;
