import Main from './Main';
import { BrowserRouter as Router } from 'react-router-dom';
import React from 'react';
import { ToastContainer } from 'react-toastify';
import 'antd/dist/antd.min.css'
const App = () => {
  return (
    <Router>
      <div>
        <div>
          <Main />
        </div>
        <ToastContainer
          position="top-right"
          autoClose={3500}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </div>
    </Router>
  );
};

export default App;
