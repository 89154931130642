import vi from './vi';
import en from './en';

const getLang = new URL(location.href).searchParams.get('lang');

const langData = (getLang && (getLang === 'vi' || getLang === 'en')) ? getLang : 'en'
let lang = {};

if (langData === 'vi') {
  lang = vi;
} else if (langData === 'en') {
  lang = en;
}

export default lang;
