/* eslint-disable camelcase */
import { React, useEffect, useState } from 'react'
import { Image, Pagination, Table } from 'antd'
import noImage from '../../../assets/images/no-image.png'
import { useDispatch, useSelector } from 'react-redux'
import { setCampaignQueryData } from '../slice'
import * as campaignThunk from '../thunk'
import lang from '../../../language/language'
import { currencyExchangeVND } from '../../../constants/common'

const ListCampaign = (props) => {
    const dataList = useSelector(state => state.campaign.campaignList)
    const campaignQueryData = useSelector(state => state.campaign.campaignQueryData)
    const [loading, setLoading] = useState(false)
    const getLang = new URL(location.href).searchParams.get('lang');

    const dispatch = useDispatch()

    const platform = {
        1: 'PC',
        2: 'Mobile',
        3: 'Tablet'
    }

    // const pricingType = {
    //     2: 'CPA',
    //     3: 'CPS '
    // }

    const covertCurrencyToVND = (currency, price) => {
        const formatter = new Intl.NumberFormat('vi-VI', {
            style: 'currency',
            currency: 'VND',
            minimumFractionDigits: 0
        })
        let result = null
        if (currency === 'AED') {
            result = formatter.format(price * currencyExchangeVND.AED)
        } else if (currency === 'AUD') {
            result = formatter.format(price * currencyExchangeVND.AUD)
        } else if (currency === 'CNY') {
            result = formatter.format(price * currencyExchangeVND.CNY)
        } else if (currency === 'EUR') {
            result = formatter.format(price * currencyExchangeVND.EUR)
        } else if (currency === 'GBP') {
            result = formatter.format(price * currencyExchangeVND.GBP)
        } else if (currency === 'HKD') {
            result = formatter.format(price * currencyExchangeVND.HKD)
        } else if (currency === 'INR') {
            result = formatter.format(price * currencyExchangeVND.INR)
        } else if (currency === 'KRW') {
            result = formatter.format(price * currencyExchangeVND.KRW)
        } else if (currency === 'MYR') {
            result = formatter.format(price * currencyExchangeVND.MYR)
        } else if (currency === 'SGD') {
            result = formatter.format(price * currencyExchangeVND.SGD)
        } else if (currency === 'USD') {
            result = formatter.format(price * currencyExchangeVND.USD)
        } else if (currency === 'YEN') {
            result = formatter.format(price * currencyExchangeVND.YEN)
        } else {
            result = price + ' ' + currency
        }
        return result
    }

    const columns = [
        {
            title: '',
            dataIndex: 'offer',
            key: 'thumbnail',
            width: 150,
            // thumbnail
            render: (item) => <Image
                width={50}
                height={50}
                src={item.thumbnail || noImage}
            />
        },
        {
            title: lang.id,
            dataIndex: 'offer',
            key: 'id',
            width: 50,
            // sort
            sorter: true,
            // id
            render: (item) => <span>{item.id}</span>
        },
        {
            title: lang.name,
            dataIndex: 'offer',
            key: 'name',
            width: 500,
            // name
            render: (item) => <span>{item.name}</span>
        },
        // {
        //     title: lang.category,
        //     dataIndex: 'offer',
        //     key: 'category',
        //     width: 200,
        //     // category
        //     render: (item) => {
        //         console.log(item)
        //         return <Tag color={'#f50'}>{item.category}</Tag>
        //     }
        // },
        {
            title: lang.platform,
            dataIndex: 'offer_platform',
            key: 'platform',
            width: 200,
            // platform
            render: (i) => {
                if (i.target && i.target.length > 0) {
                    const cont = i.target && i.target.map((x) => {
                        return `${platform[x.platform]}: ${x.system}`
                    })
                    return cont ? cont.join(' - ') : ''
                } else {
                    return lang.all_platform
                }
            }
        },
        {
            title: lang.country,
            dataIndex: 'offer_geo',
            key: 'country',
            width: 200,
            // country
            render: (i) => {
                if (i.taget && i.taget.length > 0) {
                    const cont = i.taget && i.taget.map((x) => {
                        return x.country
                    })
                    return cont.join(', ')
                } else {
                    return lang.all_country
                }
            }
        },
        {
            title: lang.payout,
            dataIndex: 'offer',
            key: 'payout',
            width: 300,
            // payout
            render: (item) => {
                if (item.pricing_type === '2') {
                    if (getLang === 'vi') {
                        return covertCurrencyToVND(item.currency, item.payout)
                    } else {
                        if (item.currency === 'VND') {
                            const formatter = new Intl.NumberFormat('vi-VI', {
                                style: 'currency',
                                currency: 'VND',
                                minimumFractionDigits: 0
                            })
                            return <span>{formatter.format(item.payout)}</span>
                        } else {
                            return <span>{item.payout + ' ' + item.currency}</span>
                        }
                    }
                } else if (item.pricing_type === '3') {
                    return <span>{`${item.percent_payout}%`}</span>
                }
            }
        }
    ]

    useEffect(() => {
        setLoading(false)
    }, [dataList])

    useEffect(() => {
        // dispatch(setCampaignQueryData({ ...campaignQueryData, type: 'personal', sort: '-id', offset: 2, 'filters[p ricing_type]': 'CPC', 'filters[geo_country]': 'CN' }))
        fetchDataList(campaignQueryData)
    }, [campaignQueryData])

    const fetchDataList = (queryData) => {
        setLoading(true)
        dispatch(campaignThunk.campaignlist(queryData))
    }

    const onChangePagination = (page, pageSize) => {
        dispatch(setCampaignQueryData({ ...campaignQueryData, offset: page, limit: pageSize }))
        window.scrollTo(0, 0)
    }

    const onChangeTable = (pagination, filters, sorter) => {
        setLoading(true)
        const sortData = sorter.order === 'ascend' ? '+id' : '-id'
        dispatch(setCampaignQueryData({ ...campaignQueryData, sort: sortData }))
    }

    return (
        <>
            <Table
                columns={columns}
                dataSource={dataList.rowset}
                pagination={false}
                loading={loading}
                scroll={{
                    x: 500
                }}
                onChange={onChangeTable}
            />
            <Pagination
                showTotal={(total, range) => `${range[0]}-${range[1]} / ${lang.total.toLowerCase()} ${total}`}
                pageSizeOptions={[5, 10, 20, 50, 100]}
                total={dataList.totalRows}
                pageSize={campaignQueryData ? campaignQueryData.limit : 20}
                current={campaignQueryData ? campaignQueryData.offset : 1}
                onChange={onChangePagination}
                style={{ marginTop: 15 }}
            />
        </>
    )
};

export default ListCampaign;
