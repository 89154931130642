import { createSlice } from '@reduxjs/toolkit';
import * as campaignThunk from './thunk';

export const campaignSlice = createSlice({
  name: 'campaign',
  initialState: {
    campaignList: [],
    campaignQueryData: {
      offset: 1,
      limit: 20,
      status: 1,
      sort: '-id',
      'filters[id]': '',
      'filters[name]': '',
      'filters[pricing_type]': '',
      'filters[offer_approval]': '',
      'filters[currency]': '',
      'filters[conversion_protocol]': '',
      'filters[geo_country]': '',
      'filters[offer_platform]': '',
      'filters[category]': ''
    }
  },
  reducers: {
    setCampaignQueryData: (state, action) => {
      state.campaignQueryData = action.payload;
    }
  },
  extraReducers: {
    // Add reducers for additional action types here, and handle loading state as needed
    [campaignThunk.campaignlist.fulfilled]: (state, action) => {
      state.campaignList = action.payload;
    }
  }
});

export const {
  setCampaignQueryData
} = campaignSlice.actions;

export default campaignSlice.reducer;
