const vi = {
    campaign: 'Chiến dịch',
    id: 'Mã',
    name: 'Tên',
    category: 'Danh mục',
    platform: 'Nền tảng',
    country: 'Quốc gia',
    payout: 'Thanh toán ra (CPI, CPA, CPS, CPO)',
    search: 'Tìm kiếm',
    apply: 'Áp dụng',
    clear: 'Làm mới',
    total: 'Tổng',
    page: 'Trang',
    offersName: 'Tên, mã',
    select: 'Tìm kiếm',
    all_platform: 'Tất cả nền tảng',
    all_country: 'Tất cả quốc gia'
};

export default vi;
