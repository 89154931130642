const en = {
    campaign: 'Campaign',
    id: 'ID',
    name: 'Name',
    category: 'Category',
    platform: 'Platform',
    country: 'Country',
    payout: 'Payout (CPI, CPA, CPS, CPO)',
    search: 'Search',
    apply: 'Apply',
    clear: 'Clear',
    total: 'Total',
    page: 'Page',
    offersName: 'Offers id, name',
    select: 'Select',
    all_platform: 'All Platform',
    all_country: 'All Country'
};

export default en;
