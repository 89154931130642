import { Switch, Route, BrowserRouter as Router } from 'react-router-dom';
import Routes from './routes';
import 'react-toastify/dist/ReactToastify.css';
import React from 'react';

const Main = () => {
  const showApp = (routes) => {
    let result = null;
    // const menus = [];
    if (routes.length > 0) {
      result = routes.map((route, index) => {
        return (
          <Route
            key={index}
            path={route.path}
            exact={route.exact}
            component={route.main}
          />
        );
      });
    }
    return (
      <Switch>
        {result}
      </Switch>
    );
  };
  return (
    <Router>
      {showApp(Routes)}
    </Router>
  );
};

export default Main;
